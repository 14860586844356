@import '@/styles/variables.scss';



















































































































































































































































































.v-application.skin-variant--bordered .theme--light.v-card--cc:not(.v-card .v-card) {
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 1px;
}
