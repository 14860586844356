@import '@/styles/variables.scss';
































































































































































































































































































































































































































































































.v-card-actions--custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: -10px;
  .v-btn + .v-btn {
    margin-right: -20px !important;
  }
}
.vue-tel-input-vuetify {
  align-items: flex-start;
}
.vue-tel-input-vuetify .v-input--has-state {
  transform: translateY(0px);
}
