@import '@/styles/variables.scss';






























































































































































































































































































































































































































































































































.v-card-actions--custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: -10px;
  .v-btn + .v-btn {
    margin-right: -20px;
  }
}
